import { combineReducers } from "redux";
import widgetReducer from "../containers/PlayerScreen/reducers";
import webSocketReducer from "../webSocket/reducers";

const rootReducer = combineReducers({
	widgets: widgetReducer,
	webSocket: webSocketReducer,
});

export default rootReducer;
