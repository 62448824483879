import axios from 'axios';

const apiCallsForWidget = {
	getPlayerID(url) {
		return axios
			.get(url)
			.then((res) => {
				return res.data.playerId;
			})
			.catch((err) => {
				console.log(err.response);
			});
	},

	getWidgets(url) {
		return axios.get(url).then((res) => {
			return res.data.widgets;
		});
	},

	getWidgetContent(url) {
		return axios
			.get(url)
			.then((res) => {
				return {
					lifetime: parseInt(res.data[0].lifetime),
					mime_type: res.data[0].details.mime_type,
					source: res.data[0].details.uri,
					itemID: res.data[0].itemID,
					timestamp: res.data[0].timestamp,
					transitionEnter:
						res.data[0].details.sTransitionTypeEnter.split('-')[1],
					transitionExit:
						res.data[0].details.sTransitionTypeExit.split('-')[1],
					transitionDuration: res.data[0].details.iTransitionDuration,
					height: res.data[0].details.height,
					width: res.data[0].details.width,
					scale: res.data[0].details.scale,
					contentItemID: res.data[0].contentItemID,
					// audio:
					// 	res.data.length === 2 ? res.data[1].details.audios[0].filePath : "",
					slides: res.data[0].details.slides,
					widgetFullContent:
						res.data.length === 2 ? res.data[1] : res.data[0],
					widgetLength: res.data.length,
				};
			})
			.catch((error) => {
				console.log(error);
			});
	},
};

export default apiCallsForWidget;
