import React from 'react';
import { Video, Image, Iframe, Newsbar, Rubric } from '../../components';
import './style/Transitions.css';
import widgetContentUtilities from '../WidgetContentUtilities';

const RenderTypeOfContent = {
	renderContent(style, widget, timestamp) {
		const combinedStyle = {
			...style,
			...widget.style,
		};
		const {
			mime_type,
			source,
			audio,
			transitionEnter,
			transitionDuration,
			itemID,
			widgetLength,
			lifetime,
		} = widget.content;

		const { typeID } = widget;

		if (mime_type && transitionEnter) {
			if (mime_type === 'video/mp4' && widgetLength === 1) {
				let root = document.documentElement;
				root.style.setProperty(
					'--animation-duration',
					transitionDuration + 's'
				);
				return (
					<div
						style={combinedStyle}
						key={timestamp}
						className={transitionEnter}
					>
						<Video
							source={source}
							objectFit={combinedStyle.objectFit}
							zIndex={combinedStyle.zIndex}
							id={itemID}
							audio={audio}
						></Video>
					</div>
				);
			} else if (typeID === 2) {
				const { rubricDetails } =
					widgetContentUtilities.widgetNewsContent(
						widget.content.widgetFullContent
					);

				return (
					<div
						style={style}
						key={timestamp}
						className={transitionEnter}
					>
						<Rubric
							// source={source !== undefined ? source : null}
							objectFit={combinedStyle.objectFit}
							zIndex={combinedStyle.zIndex}
							id={itemID}
							rubricNewsIntroLifetime={lifetime}
							rubricDetails={rubricDetails}
							audio={audio}
						></Rubric>
					</div>
				);
			} else if (
				mime_type === 'image/jpg' ||
				mime_type === 'image/jpeg' ||
				mime_type === 'image/png'
			) {
				return (
					<div
						style={combinedStyle}
						key={timestamp}
						className={transitionEnter}
					>
						<Image
							source={source}
							objectFit={combinedStyle.objectFit}
							zIndex={combinedStyle.zIndex}
							id={itemID}
							audio={audio}
						></Image>
					</div>
				);
			} else if (mime_type === 'zip/archive') {
				return (
					<div style={style} key={timestamp}>
						<Iframe
							source={source}
							objectFit={combinedStyle.objectFit}
							zIndex={combinedStyle.zIndex}
							id={itemID}
							audio={audio}
						></Iframe>
					</div>
				);
			} else if (mime_type === 'newsbar') {
				const { title, content, contentKeys, transitionTimer } =
					widgetContentUtilities.newsbarContent(widget.content);

				return (
					<div style={style} key={timestamp}>
						<Newsbar
							title={title}
							zIndex={combinedStyle.zIndex}
							content={content}
							contentKeys={contentKeys}
							transitionTimer={transitionTimer}
							id={itemID}
							audio={audio}
						></Newsbar>
					</div>
				);
			}
		}
	},
};

export default RenderTypeOfContent;
