import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import RenderTypeOfContent from '../../helpers/RenderTypeOfWidgetContent';
import getWidgetDetails from '../../helpers/getWidgetDetailsApi';

import fallbackVideo from '../../helpers/fallbackImages/fallbackVideo.mp4';

import {
	setWidgetContent,
	setEventWidgetContent,
} from '../../helpers/setWidgetContent';

const WidgetContentContainer = ({ widgetID, style }) => {
	const [widget, setWidget] = useState('');
	const [nextWidget, setNextWidget] = useState('');
	const [timestamp, setTimeStamp] = useState('');
	const [nextTimestamp, setNextTimestamp] = useState('');
	const [currentTimeout, setCurrentTimeout] = useState('');
	const [resumedContent, setResumedContent] = useState('');
	const [fallback, setFallback] = useState({
		fallback: false,
	});

	const webSocketPlayNow = useSelector(
		(state) => state.webSocket.playNowData
	);

	useEffect(async () => {
		if (nextWidget === '') {
			try {
				let data = {
					...(await setWidgetContent(widgetID)
						.then((res) => {
							return res;
						})
						.catch(() => {})),
				};

				setWidget({
					...data,
				});
				/*FIRST WIDGET START */
				getWidgetDetails.recordPlayerStart(
					widgetID,
					data.content.widgetFullContent
				);
				setTimeStamp(data.timestamp);
			} catch {
				setFallback({
					fallback: true,
					content: {
						source: fallbackVideo,
						mime_type: 'video/mp4',
						transitionEnter: 'fade',
						widgetLength: 1,
					},
				});
				const intervalFallback = setInterval(async () => {
					let data = {
						...(await setWidgetContent(widgetID).then((res) => {
							return res;
						})),
					};
					if (data.content) {
						clearInterval(intervalFallback);
						setFallback({
							...fallback,
							fallback: false,
						});
						setWidget({
							...data,
						});
						getWidgetDetails.recordPlayerStart(
							widgetID,
							data.content.widgetFullContent
						);
						setTimeStamp(data.timestamp);
					}
				}, 10000);
			}
		}
		if (nextWidget !== '') {
			try {
				let data = {
					...(await setWidgetContent(widgetID).then((res) => {
						return res;
					})),
				};

				setCurrentTimeout(
					setTimeout(
						() => {
							nextWidget.style.zIndex = 0;
							setWidget({
								...data,
							});
							getWidgetDetails.recordPlayerEnd(
								widgetID,
								nextWidget.content.widgetFullContent
							);
							/*MAKING THE NEXT WIDGET START CALL*/
							getWidgetDetails.recordPlayerStart(
								widgetID,
								data.content.widgetFullContent
							);
							setTimeStamp(data.timestamp);
						},
						data.content.mime_type === 'newsbar'
							? nextWidget.newsbarTimer * 1000 + 1800
							: nextWidget.content.widgetLength === 2
							? nextWidget.widgetNewsTimer * 1000
							: nextWidget.content.lifetime * 1000
					)
				);
				setResumedContent({ ...data });
			} catch {
				setFallback({
					fallback: true,
					content: {
						source: fallbackVideo,
						mime_type: 'video/mp4',
						transitionEnter: 'fade',
						widgetLength: 1,
					},
				});
				let intervalFallback = setInterval(async () => {
					let data = {
						...(await setWidgetContent(widgetID).then((res) => {
							return res;
						})),
					};
					if (data.content) {
						clearInterval(intervalFallback);
						setFallback({
							...fallback,
							fallback: false,
						});
						nextWidget.style.zIndex = 0;
						setWidget({
							...data,
						});
						getWidgetDetails.recordPlayerEnd(
							widgetID,
							nextWidget.content.widgetFullContent
						);
						getWidgetDetails.recordPlayerStart(
							widgetID,
							data.content.widgetFullContent
						);
						setTimeStamp(data.timestamp);
					}
				}, 10000);
			}
		}
	}, [nextTimestamp]);

	useEffect(async () => {
		if (widget !== '' && widget.lifetime !== '') {
			try {
				let data = {
					...(await setWidgetContent(widgetID).then((res) => {
						return res;
					})),
				};

				setCurrentTimeout(
					setTimeout(
						() => {
							widget.style.zIndex = 0;
							setNextWidget({
								...data,
							});
							getWidgetDetails.recordPlayerEnd(
								widgetID,
								widget.content.widgetFullContent
							);
							getWidgetDetails.recordPlayerStart(
								widgetID,
								data.content.widgetFullContent
							);
							setNextTimestamp(data.timestamp);
						},
						data.content.mime_type === 'newsbar'
							? widget.newsbarTimer * 1000 + 1800
							: widget.content.widgetLength === 2
							? widget.widgetNewsTimer * 1000
							: widget.content.lifetime * 1000
						/*MAYBE HERE */
					)
				);
				setResumedContent({
					...data,
				});
			} catch {
				setFallback({
					fallback: true,
					content: {
						source: fallbackVideo,
						mime_type: 'video/mp4',
						transitionEnter: 'fade',
						widgetLength: 1,
					},
				});
				const intervalFallback = setInterval(async () => {
					let data = {
						...(await setWidgetContent(widgetID).then((res) => {
							return res;
						})),
					};
					if (data.content) {
						clearInterval(intervalFallback);
						setFallback({
							...fallback,
							fallback: false,
						});
						widget.style.zIndex = 0;
						setNextWidget({
							...data,
						});
						getWidgetDetails.recordPlayerEnd(
							widgetID,
							widget.content.widgetFullContent
						);
						getWidgetDetails.recordPlayerStart(
							widgetID,
							data.content.widgetFullContent
						);
						setNextTimestamp(data.timestamp);
					}
				}, 10000);
			}
		}
	}, [timestamp]);

	/*PLAY NOW EVENT*/
	useEffect(() => {
		if (webSocketPlayNow.widgetID) {
			if (
				widget &&
				webSocketPlayNow.widgetID == widgetID &&
				widget.style.zIndex === 1
			) {
				webSocketPlayNow.lifetime = Math.round(
					parseInt(webSocketPlayNow.lifetime)
				);
				clearInterval(currentTimeout);
				let currentContent = {
					...widget,
				};
				setWidget({ ...setEventWidgetContent(webSocketPlayNow) });
				getWidgetDetails.recordPlayerStart(widgetID, webSocketPlayNow);

				setTimeout(() => {
					getWidgetDetails.recordPlayerEnd(
						widgetID,
						webSocketPlayNow
					);
					setWidget({
						...currentContent,
					});
					getWidgetDetails.recordPlayerStart(
						widgetID,
						currentContent.content.widgetFullContent
					);
				}, webSocketPlayNow.lifetime * 1000);

				setTimeout(() => {
					widget.style.zIndex = 0;
					getWidgetDetails.recordPlayerEnd(
						widgetID,
						currentContent.content.widgetFullContent
					);
					setNextWidget({ ...resumedContent });
					getWidgetDetails.recordPlayerStart(
						widgetID,
						resumedContent.content.widgetFullContent
					);
					setNextTimestamp(webSocketPlayNow.timestamp);
				}, webSocketPlayNow.lifetime * 1000 + (currentContent.widgetNewsTimer ? currentContent.widgetNewsTimer * 1000 : currentContent.content.lifetime * 1000));
			}

			if (
				nextWidget &&
				webSocketPlayNow.widgetID == widgetID &&
				nextWidget.style.zIndex === 1
			) {
				webSocketPlayNow.lifetime = Math.round(
					parseInt(webSocketPlayNow.lifetime)
				);
				clearInterval(currentTimeout);
				let currentContent = {
					...nextWidget,
				};

				setNextWidget({ ...setEventWidgetContent(webSocketPlayNow) });
				getWidgetDetails.recordPlayerStart(widgetID, webSocketPlayNow);

				setTimeout(() => {
					getWidgetDetails.recordPlayerEnd(
						widgetID,
						webSocketPlayNow
					);
					setNextWidget({ ...currentContent });
					getWidgetDetails.recordPlayerStart(
						widgetID,
						currentContent.content.widgetFullContent
					);
				}, webSocketPlayNow.lifetime * 1000);

				setTimeout(() => {
					nextWidget.style.zIndex = 0;
					getWidgetDetails.recordPlayerEnd(
						widgetID,
						currentContent.content.widgetFullContent
					);
					setWidget({ ...resumedContent });
					getWidgetDetails.recordPlayerStart(
						widgetID,
						resumedContent.content.widgetFullContent
					);
					setTimeStamp(webSocketPlayNow.timestamp);
				}, webSocketPlayNow.lifetime * 1000 + (currentContent.widgetNewsTimer ? currentContent.widgetNewsTimer * 1000 : currentContent.content.lifetime * 1000));
			}
		}
	}, [webSocketPlayNow.timestamp]);

	return (
		<div id={widgetID}>
			{widget !== '' &&
				widget.content &&
				!fallback.fallback &&
				RenderTypeOfContent.renderContent(style, widget, timestamp)}

			{nextWidget !== '' &&
				nextWidget.content &&
				!fallback.fallback &&
				RenderTypeOfContent.renderContent(
					style,
					nextWidget,
					nextTimestamp
				)}
			{fallback.fallback &&
				RenderTypeOfContent.renderContent(style, fallback, '')}
		</div>
	);
};

export default WidgetContentContainer;
