import getWidgetDetails from './getWidgetDetailsApi';
import widgetContentUtilities from './WidgetContentUtilities';

const fetchContent = async (widgetID) => {
	return await getWidgetDetails.getWidgetContent(widgetID);
};

export const setWidgetContent = (widgetID) => {
	return fetchContent(widgetID)
		.then((data) => {
			if (data !== undefined) {
				return {
					content: data,
					style: {
						objectFit: widgetContentUtilities.setScale(data),
						zIndex: 1,
					},
					newsbarTimer:
						data.mime_type === 'newsbar'
							? widgetContentUtilities.newsbarContent(data)
									.transitionTimer
							: ' ',
					widgetNewsTimer:
						data.widgetFullContent.typeID === 2
							? data.lifetime + data.widgetFullContent.lifetime
							: 0,
					nextzIndex: 0,
					timestamp: data.timestamp,
					typeID: data.widgetFullContent.typeID,
				};
			}
		})
		.catch((error) => {});
};

export const setEventWidgetContent = (websocketEventData) => {
	if (websocketEventData !== undefined) {
		return {
			content: {
				...websocketEventData,
			},
			style: {
				objectfit: widgetContentUtilities.setScale(websocketEventData),
				zIndex: 1,
			},
			newsbarTimer:
				websocketEventData.mime_type === 'newsbar'
					? widgetContentUtilities.newsbarContent(websocketEventData)
							.transitionTimer
					: ' ',
			widgetNewsTimer:
				websocketEventData.widgetLength === 2
					? websocketEventData.lifetime +
					  websocketEventData.widgetFullContent.lifetime
					: 0,
			nextzIndex: 0,
		};
	}
};
