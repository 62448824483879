export const mediaServer = () => {
  let urlString = window.location.href;
  let url = new URL(urlString);
  let mediaServer = url.searchParams.get("mediaServerUrl");
  return mediaServer ? mediaServer : "https://ms.local.cms.wink.ro/api/";
};

export const mediaServerWS = () => {
  let urlString = window.location.href;
  let url = new URL(urlString);
  let mediaServerWS = url.searchParams.get("mediaServerWSURL");
  return mediaServerWS ? mediaServerWS : "wss://ms.local.cms.wink.ro/";
};

export const apiConfig = {
  API_URL: mediaServer(),
  PATHS: {
    IDENTIFY: "identify/?getjson=1",
    HELLO: (playerID) => {
      return `hello/${playerID}/?getjson=1`;
    },
    CONTENT: (widgetID) => {
      return `content/${widgetID}/?getjson=1`;
    },
    RECORD: (widgetID) => {
      return `record/${widgetID}/?getjson=1&`;
    },
  },
};
