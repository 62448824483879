import apiCallsForWidget from './prerequisitesApiCallsForWidget';
import axios from 'axios';
import { apiConfig } from './apiConfig';

let recordID;

const getWidgetDetails = {
	async getPlayerID() {
		let playerID = await apiCallsForWidget.getPlayerID(
			apiConfig.API_URL + apiConfig.PATHS.IDENTIFY
		);
		return playerID;
	},

	async getWidgetID(playerID) {
		let widgetID = await apiCallsForWidget.getWidgets(
			apiConfig.API_URL + apiConfig.PATHS.HELLO(playerID)
		);

		return widgetID;
	},

	async getWidgetContent(widgetID) {
		let widgetContent = await apiCallsForWidget.getWidgetContent(
			apiConfig.API_URL + apiConfig.PATHS.CONTENT(widgetID)
		);
		return widgetContent;
	},

	recordPlayerStart(widgetID, widget) {
		const obj = {
			itemID: widget.itemID,
			contentID: widget.contentID,
			groupID: widget.groupID !== undefined ? widget.groupID : '',
			contentItemID: widget.contentItemID,
			typeID: widget.typeID,
			start: Math.floor(Date.now() / 1000),
		};
		let str = '';
		for (let key in obj) {
			if (str !== '') {
				str += '&';
			}
			str += key + '=' + obj[key];
		}
		return axios
			.post(apiConfig.API_URL + apiConfig.PATHS.RECORD(widgetID) + str)
			.then((res) => {
				recordID = JSON.stringify(res.data.recordID);
			});
	},
	recordPlayerEnd(widgetID, widget) {
		const obj = {
			itemID: widget.itemID,
			contentID: widget.contentID,
			groupID: widget.groupID,
			contentItemID: widget.contentItemID,
			typeID: widget.typeID,
			recordID,
			end: Math.floor(Date.now() / 1000),
		};
		let str = '';
		for (let key in obj) {
			if (str !== '') {
				str += '&';
			}
			str += key + '=' + obj[key];
		}
		return axios.get(
			apiConfig.API_URL + apiConfig.PATHS.RECORD(widgetID) + str
		);
	},
};

export default getWidgetDetails;
