const widgetContentUtilities = {
	newsbarContent(widget) {
		let title = '';
		let content = [];
		let contentKeys = [];
		let transitionTimer = 0;

		for (let i = 0; i < widget.contentItemID.length; i++) {
			title =
				widget.slides[
					widget.contentItemID[i]
				].textLogos.sourceName.split(' ')[0];
			content.push(
				widget.slides[widget.contentItemID[i]].newsOriginalTitle
			);
			contentKeys.push(widget.contentItemID[i]);
			transitionTimer += content[i].length / 6;
		}
		return {
			title,
			content,
			contentKeys,
			transitionTimer: parseInt(transitionTimer),
		};
	},
	widgetNewsContent(widget) {
		let windowWidth = window.innerWidth;
		let windowHeight = window.innerHeight;
		let imageDetails = [];
		let newsTitle = [];
		let newsContent = [];
		let newsLifetime = 0;
		let output = [];
		let outputLifetime = 0;
		let paragraphHeight = 40;
		let spaceSeeker = 160;
		let fontSize = 71;
		let rubricDetails = [];

		for (let i = 0; i < widget.contentItemID.length; i++) {
			newsTitle =
				widget.details.slides[widget.contentItemID[i]].newsTitle;
			newsContent.push(
				widget.details.slides[widget.contentItemID[i]].newsContent
			);
			for (
				let j = 0;
				j <
				widget.details.slides[widget.contentItemID[i]].images.length;
				j++
			) {
				imageDetails.push({
					src: widget.details.slides[widget.contentItemID[i]].images[
						j
					].imagePath,
					duration:
						widget.details.slides[widget.contentItemID[i]]
							.duration /
						widget.details.slides[widget.contentItemID[i]].images
							.length,
				});
			}
			newsLifetime =
				widget.details.slides[widget.contentItemID[i]].duration;

			newsContent[i] = newsContent[i].replace(
				/(<p[^>]+?>|<p>|<\/p>|<br|<br>|<\/br>|>|\/)/g,
				''
			);
			if (
				windowHeight >= 860 &&
				windowHeight <= 1080 &&
				windowWidth >= 1800 &&
				windowWidth <= 1920
			) {
				paragraphHeight = 40;
			} else if (
				windowHeight >= 660 &&
				windowHeight <= 880 &&
				windowWidth >= 1630 &&
				windowWidth <= 1780
			) {
				paragraphHeight = 40;
				spaceSeeker = 150;
				fontSize = 70;
			} else if (
				windowHeight >= 500 &&
				windowHeight <= 660 &&
				windowWidth >= 1420 &&
				windowWidth <= 1620
			) {
				paragraphHeight = 60;
				spaceSeeker = 140;
				fontSize = 65;
			}

			let x = 0;
			let characters = 0;
			while (x === 0) {
				if (characters + spaceSeeker < newsContent[i].length) {
					if (newsContent[i][characters + spaceSeeker] === ' ') {
						output.push(
							newsContent[i].substring(
								characters,
								characters + spaceSeeker
							)
						);
						characters += spaceSeeker;
					} else {
						do {
							spaceSeeker++;
							if (
								newsContent[i][characters + spaceSeeker] ===
								undefined
							)
								break;
						} while (
							newsContent[i][characters + spaceSeeker] !== ' '
						);
						if (newsContent[i][spaceSeeker + 1] === ' ') {
							output.push(
								newsContent[i].substring(
									characters,
									characters + spaceSeeker
								)
							);
							characters += spaceSeeker;
						}
					}
				} else x = 1;
			}

			if (x === 1) {
				output.push(
					newsContent[i].substring(characters, newsContent[i].length)
				);
			}
			outputLifetime = newsLifetime / (output.length + 1);
			rubricDetails.push({
				newsTitle,
				output,
				outputLifetime,
				imageDetails,
				newsLifetime,
				paragraphHeight,
				fontSize,
			});
			output = [];
			imageDetails = [];
		}

		return {
			rubricDetails,
		};
	},
	setScale(widget) {
		if (widget.scale === 'DOWN' || widget.scale === 'down') {
			return 'contain';
		} else if (widget.scale === 'up' || widget.scale === 'UP') {
			return 'cover';
		} else {
			return 'fill';
		}
	},
};

export default widgetContentUtilities;
